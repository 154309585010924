import React, { useState } from "react";
import { useContent} from "../context/main.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function CoverImage() {
  const { contentType } = useContent();
  const navigate = useNavigate();
  const {file, setFile} = useContent();
  const [preview, setPreview] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (!validImageTypes.includes(selectedFile.type)) {
        alert("Invalid file type. Please select a JPG or PNG image.");
        setPreview("");
        setFile(null);
        return;
      }

      const img = new Image();
      const reader = new FileReader();

      reader.onloadend = () => {
        img.src = reader.result;
        img.onload = () => {
          if (img.width === 3000 && img.height === 3000) {
            setPreview(reader.result);
            setFile(selectedFile);
          } else {
            alert("Image dimensions must be 3000x3000 pixels.");
            setPreview("");
            setFile(null);
          }
        };
        img.onerror = () => {
          alert("Error loading image.");
          setPreview("");
          setFile(null);
        };
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmitClick = () => {
    if (!file) {
      alert("Please upload an image before proceeding.");
      return;
    }
    navigate("/upload-submission");
  };

  return (
    <div>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div
        className="container1"
        style={{ width: "auto", minHeight: "700px", height: "auto" }}
      >
        <div className="flex flex-col items-start profileBar w-[28%] bg-[#ffffff0d] rounded-md ">
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <Link to="/details-audio">Details</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/content-distribution">Content Distribution</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/terms-uploads">T&C</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/iprs">IPRS</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && (
              <Link to="/attach-audio">Attach Audio</Link>
            )}
            {contentType === "video" && (
              <Link to="/attach-audio">Attach Video</Link>
            )}
          </button>
          <br />
          <button className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/cover-image">Select Cover Image</Link>
          </button>
        </div>
        <div className=" mt-[4%] flex flex-col items-center justify-center w-[70%]">
          <div className="w-[35rem] bg-[#ffffff0d] rounded-md p-[5%] flex flex-row justify-between">
            <div className="flex flex-col justify-between">
              <div>
                {contentType === "audio" && (
                  <h2
                    className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Upload Artwork
                  </h2>
                )}
                {contentType === "video" && (
                  <h2
                    className="text-[#FFFFFF] text-[0.9rem] mb-[2%]"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Upload Thumbnail
                  </h2>
                )}
              </div>
              <div>
                <button className="w-[8rem] h-[2.3rem] text-[#FFFFFF] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122] mb-[7%]">
                  <label htmlFor="file-upload">Choose File</label>
                </button>
                <input
                  id="file-upload"
                  type="file"
                  hidden
                  onChange={handleFileChange}
                  accept=".jpeg,.jpg,.png"
                />
              </div>
            </div>
            <div>
              {preview && (
                <img
                  src={preview}
                  alt="Preview"
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: "5px",
                  }}
                />
              )}
            </div>
          </div>
          <div className="w-[35rem] bg-[#ffffff0d] rounded-md p-[5%] my-4">
            {contentType === "audio" && (
              <div
                className="text-[#e1dddd] text-[0.8rem] mt-[1%] w-[100%] flex flex-col gap-0.5"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <div className="mb-[1rem]">
                  <span className="text-[#FF6C2F] text-[1.1rem]">
                    Points to remember before Uploading:
                  </span>
                </div>
                <div className="flex items-start">
                  <span className="text-[#FF6C2F] text-[1.2rem] mr-[0.3rem]">
                    *
                  </span>{" "}
                  <span>Artwork Dimension should be 3000 x 3000 pixels.</span>
                </div>
                <div className="flex items-start">
                  <span className="text-[#FF6C2F] text-[1.2rem] mr-[0.3rem]">
                    *
                  </span>{" "}
                  <span>
                    Please add title on the artwork in sync with the track name.
                  </span>
                </div>
                <div className="flex items-start">
                  <span className="text-[#FF6C2F] text-[1.2rem] mr-[0.3rem]">
                    *
                  </span>{" "}
                  <span>
                    Please don't add any social media handles, music platforms
                    logos and personal or office contact details.
                  </span>
                </div>
              </div>
            )}

            {contentType === "video" && (
              <div
                className="text-[#e1dddd] text-[0.8rem] mt-[1%] w-[100%] flex flex-col gap-0.5"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                <div className="mb-[1rem]">
                  <span className="text-[#FF6C2F] text-[1.1rem]">
                    Points to remember before Uploading:
                  </span>
                </div>
                <div className="flex items-start">
                  <span className="text-[#FF6C2F] text-[1.2rem] mr-[0.3rem]">
                    *
                  </span>{" "}
                  <span>Artwork Dimension should be 3000 x 3000 pixels.</span>
                </div>
                <div className="flex items-start">
                  <span className="text-[#FF6C2F] text-[1.2rem] mr-[0.3rem]">
                    *
                  </span>{" "}
                  <span>
                    Please add title on the artwork in sync with the track name.
                  </span>
                </div>
                <div className="flex items-start">
                  <span className="text-[#FF6C2F] text-[1.2rem] mr-[0.3rem]">
                    *
                  </span>{" "}
                  <span>
                    Please don't add any social media handles, music platforms
                    logos and personal or office contact details.
                  </span>
                </div>
              </div>
            )}
          </div>
          <div
            className="mb-[5%] w-[80%]"
            style={{
              flexGrow: "8",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <button
              className="text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
              style={{
                fontFamily: "Poppins, sanSerif",
              }}
              onClick={handleSubmitClick}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
