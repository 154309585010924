import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { publicRouteLink } from '../constant/Routes';
import axios from "axios";
import { toast } from "react-hot-toast";
import { MainContext } from '../context/main';
import { useNavigate } from 'react-router-dom';

const OtpVerification = ({ onClose }) => {
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [isValid, setIsValid] = useState(true);
    const { countryCode, setCountryCode, mob, setMob, buttonName, buttonValue, updateButtonInfo } = useContext(MainContext);
    const [disabled, setDisabled] = useState(false);
    const [timer, setTimer] = useState(60);
    const navigate = useNavigate();

    useEffect(() => {
        let countdown;
        if (timer > 0 && disabled) {
            countdown = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        } else {
            setDisabled(false);
        }
        return () => {
            clearTimeout(countdown);
        };
    }, [timer, disabled]);

    const handleOtpChange = (index, value) => {
        if (isNaN(value)) {
            return;
        }
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value !== '' && index < 3) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const verifyOTP = async () => {
        try {
            const resp = await axios({
                url: ' https://haartistaloud-backend.kollywoodhungama.com/aa/users/verify-otp',
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                data: {
                    "mob": mob,
                    "countryCode": "+" + countryCode,
                    "otp": otp.join('')
                }
            });

            if (resp.data.statusCode === 200) {
                toast.success("OTP Verified!");
                console.log('Button value is:', buttonValue);
                if (buttonValue === 'SignIn') {
                    navigate(publicRouteLink.DASHBOARD);
                    return;
                }
                else {
                    navigate(publicRouteLink.COMPLETE_YOUR_ACCOUNT);
                    return;
                }
            }
            else {
                setIsValid(false);
                toast.error(resp.data.message);
                return;
            }
        }
        catch (error) {
            console.log("Error from verifyOTP function:", error);
            return
        }
    };

    const sendOTP = async () => {
        try {
            const resp = await axios({
                url: " https://haartistaloud-backend.kollywoodhungama.com/aa/users/send-otp",
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                data: {
                    "mob": mob,
                    "countryCode": "+" + countryCode,
                }
            });
            if (resp.statusCode === 200) {
                toast.success("OTP sent successfully!");
            }
            else {
                toast.error("OTP not sent. Please try again!");
            }
            setTimer(60);
            setDisabled(true);
            return;
        }
        catch (error) {
            console.log("Error from sendOTP function:", error);
            return
        }
    };

    return (
        <div className="flex flex-col items-center justify-center">
            <div className='flex bg-white rounded-[4%]'>
                <div className='w-80 mt-9 ml-10'>
                    <h2 className="text-3xl font-semibold mb-3 text-[#FF6C2F]">Enter verification code</h2>
                    <p className='text-[#181818] text-sm font-medium mb-7 break-words'>We have just sent a verification code to +{countryCode}{mob} </p>
                    <div className="flex mb-7">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                className="w-9 h-12 text-center bg-[#E6E6E6] text-[#FF6C2F] text-xl font-medium border border-black border-solid rounded-md mx-2"
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                            />
                        ))}
                    </div>

                    {!isValid &&
                        <p className='text-[#FA3235]'>
                            The security code is invalid or expired.
                        </p>
                    }

                    <div className="flex items-center">
                        <button
                            onClick={sendOTP}
                            disabled={disabled}
                            style={{ color: disabled ? 'cursor-not-allowed' : 'hover:bg-white' }}
                            className={`text-[#2EB4FF] text-sm font-normal px-2 mb-7`}>
                            Send the code again
                        </button>
                        {!disabled && <span style={{ color: '#FF6C2F' }}> (00:{timer}) </span>}
                    </div>
                    <Link to={publicRouteLink.CHANGE_PHONENUMBER}>
                        <button className="text-[#2EB4FF] text-sm font-normal px-2 mb-7">
                            Change phone number
                        </button>
                    </Link>

                    <br />
                    <button onClick={verifyOTP} className="bg-[#FF6C2F] text-white text-sm font-normal px-4 py-2 mb-12 rounded-md w-80 h-12 ">
                        Verify
                    </button>
                </div>

                <div className='ml-16'>
                    <img src='assets\verification-pic.png' alt='verification-pic' className='' />
                </div>
            </div>
        </div>
    );
};

export default OtpVerification;
