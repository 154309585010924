import React, { useState } from "react";

import { Link } from "react-router-dom";
import Select from "react-select";
import { useContent } from "../context/main.js";
import AddressPopup from "./AddressPopup.js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SubGenreDropdown from "./SubGenreDropdown.js";
import LanguageDropdown from "./LanguageDropdown.js";
import KeywordDropdown from "./KeywordDropdown.js";

const Details = () => {
  const { contentType } = useContent();

  const [artistNameValue, setArtistNameValue] = useState("");
  const [artistLegalNameValue, setArtistLegalNameValue] = useState("");
  const [panCardValue, setPanCardValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [trackNameValue, setTrackNameValue] = useState("");
  const [writerValue, setWriterValue] = useState("");
  const [directorValue, setDirectorValue] = useState("");
  const [singerValue, setSingerValue] = useState("");
  const [licensorValue, setLicensorValue] = useState("");
  const [contentDescriptionValue, setContentDescriptionValue] = useState("");
  const [tagsValue, setTagsValue] = useState("");
  const [copyRightsValue, setCopyRightsValue] = useState("");
  const [publishingRightsValue, setPublishingRightsValue] = useState("");
  const [isrcValue, setIsrcValue] = useState("");

  const [isrcError, setIsrcError] = useState("");
  const [trackNameError, setTrackNameError] = useState("");
  const [artistNameError, setArtistNameError] = useState("");
  const [artistLegalNameError, setArtistLegalNameError] = useState("");
  const [panCardError, setPanCardError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [writerError, setWriterError] = useState("");
  const [directorError, setDirectorError] = useState("");
  const [singerError, setSingerError] = useState("");
  const [licensorError, setLicensorError] = useState("");
  const [contentDescriptionError, setContentDescriptionError] = useState("");
  const [tagsError, setTagsError] = useState("");
  const [copyRightsError, setCopyRightsError] = useState("");
  const [publishingRightsError, setPublishingRightsError] = useState("");
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectSubGenre, setSelectSubGenre] = useState([]);
  const [selectLanguage, setSelectLanguage] = useState([]);
  const [selectKeyword, setSelectKeyword] = useState([]);
  const [isExplicit, setIsExplicit] = useState({});

  const navigate = useNavigate();

  const handleNextClick = () => {
    const hasErrors =
      !artistNameValue ||
      !artistLegalNameValue ||
      !trackNameValue ||
      !writerValue ||
      !directorValue ||
      !singerValue ||
      !licensorValue ||
      !contentDescriptionValue ||
      !tagsValue ||
      !copyRightsValue ||
      !publishingRightsValue ||
      !panCardValue ||
      !addressValue ||
      isExplicit === "";

    if (hasErrors) {
      alert("Please fill out all required fields.");
    } else {
      navigate("/content-distribution");
    }
  };

  const handleIconClick = () => {
    setShowAddressPopup(true);
  };

  const handleCloseAddressPopup = () => {
    setShowAddressPopup(false);
  };

  const handleSaveAddress = (address, city, state, country, pincode) => {
    setAddressValue(`${address}, ${pincode}, ${city}, ${state}, ${country} `);
    toast.success("Address saved successfully");
    setShowAddressPopup(false);
  };

  const handlePancardIconClick = () => {
    setIsEditable(true);
  };

  const handleArtistNameChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setArtistNameValue(value);
      setArtistNameError("");
    } else {
      setArtistNameError("Please Enter a valid name");
    }
  };

  const handleArtistLegalNameChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z , \s]*$/;

    if (regex.test(value) && value.length <= 200) {
      setArtistLegalNameValue(value);
      setArtistLegalNameError("");
    } else {
      setArtistLegalNameError("Please Enter a valid name");
    }
  };

  const handleISRCChange = (event) => {
    const value = event.target.value.toUpperCase();

    if (value.length <= 12) {
      setIsrcValue(value);

      const regex = /^[A-Z]{2}[A-Z0-9]{3}[0-9]{2}[0-9]{5}$/;
      if (!regex.test(value)) {
        setIsrcError("Please Enter a valid name");
      } else {
        setIsrcError("");
      }
    } else {
      setIsrcError("ISRC number should be exactly 12 characters.");
    }
  };

  const handleTrackNameChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setTrackNameValue(value);
      setTrackNameError("");
    } else {
      setTrackNameError("Value cannot exceed 100 characters.");
    }
  };

  const handleDirectorChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setDirectorValue(value);
      setDirectorError("");
    } else {
      setDirectorError("Value cannot exceed 200 characters.");
    }
  };

  const handleWriterChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setWriterValue(value);
      setWriterError("");
    } else {
      setWriterError("Value cannot exceed 200 characters.");
    }
  };

  const handleSingerChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setSingerValue(value);
      setSingerError("");
    } else {
      setSingerError("Value cannot exceed 200 characters.");
    }
  };

  const handleLicensorChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setLicensorValue(value);
      setLicensorError("");
    } else {
      setLicensorError("Value cannot exceed 200 characters.");
    }
  };

  const handleContentDescriptionChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z , \s]*$/;

    if (regex.test(value) && value.length <= 200) {
      setContentDescriptionValue(value);
      setContentDescriptionError("");
    } else {
      setContentDescriptionError("Value cannot exceed 200 characters.");
    }
  };

  const handleTagsChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setTagsValue(value);
      setTagsError("");
    } else {
      setTagsError("Value cannot exceed 200 characters.");
    }
  };

  const handlePublishingRightsChange = (event) => {
    const value = event.target.value;

    if (value.length <= 200) {
      setPublishingRightsValue(value);
      setPublishingRightsError("");
    } else {
      setPublishingRightsError("Value cannot exceed 200 characters.");
    }
  };

  const handleCopyRightsChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z , \s]*$/;

    if (regex.test(value) && value.length <= 200) {
      setCopyRightsValue(value);
      setCopyRightsError("");
    } else {
      setCopyRightsError("Value cannot exceed 200 characters.");
    }
  };

  const handlePanCardChange = (event) => {
    const value = event.target.value.toUpperCase();
    if (value.length <= 10) {
      setPanCardValue(value);

      const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      if (!regex.test(value)) {
        setPanCardError("Invalid PAN Card number. Please enter a valid one.");
      } else {
        setPanCardError("");
      }
    } else {
      setPanCardError("PAN Card number should be exactly 10 characters.");
    }
  };

  const handleAddressChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9 ',+-]*$/;

    if (regex.test(value) && value.length <= 200) {
      setAddressValue(value);
      setAddressError("");
    } else if (!regex.test(value)) {
      setAddressError("Only alphanumeric values are allowed.");
    } else {
      setAddressError("The input must be exactly 200 characters.");
    }
  };

  const handleKeywordChange = (selected) => {
    setSelectKeyword(selected);
  };

  const handleYesClick = () => {
    setIsExplicit("yes");
  };

  const handleNoClick = () => {
    setIsExplicit("no");
  };

  //--------- API integration ---------\

  return (
    <>
      <div className="flex">
        {contentType === "audio" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Audio</span>
          </h2>
        )}
        {contentType === "video" && (
          <h2
            className="text-[#FFFFFF] text-[1.6rem] mt-[3%] mb-[3%] ml-[12%] flex items-center justify-center"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Create <span className="text-[#FF6C2F] ml-1">Video</span>
          </h2>
        )}
      </div>
      <div className="container1">
        <div className="flex flex-col items-start profileBar w-[28%]  bg-[#ffffff0d] rounded-md ">
          <button className="text-[#FF6C2F] text-[1.3rem] w-auto h-auto ml-[17%] mt-[13%]">
            <Link to="/details-audio">Details</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/content-distribution">Content Distribution</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/terms-uploads">T&C</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/iprs">IPRS</Link>
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            {contentType === "audio" && (
              <Link to="/attach-audio">Attach Audio</Link>
            )}
            {contentType === "video" && (
              <Link to="/attach-audio">Attach Video</Link>
            )}
          </button>
          <br />
          <button className="text-[#FFFFFF] text-[1.3rem] w-auto h-auto ml-[17%]">
            <Link to="/cover-image">Select Cover Image</Link>
          </button>
        </div>
        <div className="formDetails">
          <div>
            <label
              htmlFor=""
              className="flex font-[Poppins] text-[#FFFFFF] mt-[1%] mx-0 w-[80%] flex-row"
            >
              Artist Name
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder="Artist Aloud"
              value={artistNameValue}
              onChange={handleArtistNameChange}
              required
            />
            <br />
            {/* {stringError && <span style={{ color: "red" }}>{stringError}</span>} */}
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Artist Legal Name
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder="Artist Aloud"
              value={artistLegalNameValue}
              onChange={handleArtistLegalNameChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Track Name
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder="Artist Aloud"
              value={trackNameValue}
              onChange={handleTrackNameChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row "
            >
              Language
            </label>
            <LanguageDropdown />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Genre
            </label>
            <select
              className="single-dropdown rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] p-[2%]"
              style={{ outlineStyle: "none" }}
            >
              <option value="Acoustic">Acoustic</option>
              <option value="Anthem">Anthem</option>
              <option value="Bhajan">Bhajan</option>
              <option value="Bhangra">Bhangra</option>
              <option value="Bharatanatyam">Bharatanatyam</option>
              <option value="Bhavageete">Bhavageete</option>
              <option value="Blues">Blues</option>
              <option value="Carnatic music">Carnatic music</option>
              <option value="Chaiti">Chaiti</option>
              <option value="Classical">Classical</option>
              <option value="Comedy">Comedy</option>
              <option value="Contemperory">Contemperory</option>
              <option value="Country">Country</option>
              <option value="Dadra">Dadra</option>
              <option value="Devotional">Devotional</option>
              <option value="Devotional song">Devotional song</option>
              <option value="Dhamar">Dhamar</option>
              <option value="Dhrupad">Dhrupad</option>
              <option value="EDM">EDM</option>
              <option value="Filmi">Filmi</option>
              <option value="Filmi qawwali">Filmi qawwali</option>
              <option value="Folk music">Folk music</option>
              <option value="Funk">Funk</option>
              <option value="Fusion">Fusion</option>
              <option value="Gaana">Gaana</option>
              <option value="Ghazal">Ghazal</option>
              <option value="Hard Rock">Hard Rock</option>
              <option value="Haveli Sangeet">Haveli Sangeet</option>
              <option value="Hindi cinema">Hindi cinema</option>
              <option value="Hindustani classical music">
                Hindustani classical music
              </option>
              <option value="Hip-Hop">Hip-Hop</option>
              <option value="Hori">Hori</option>
              <option value="Indian hip hop">Indian hip hop</option>
              <option value="Indian pop">Indian pop</option>
              <option value="Indian rock">Indian rock</option>
              <option value="Inspirational">Inspirational</option>
              <option value="Khyal">Khyal</option>
              <option value="Light music">Light music</option>
              <option value="Mand">Mand</option>
              <option value="Music in ancient India">
                Music in ancient India
              </option>
              <option value="Music of Punjab">Music of Punjab</option>
              <option value="Music of Rajasthan">Music of Rajasthan</option>
              <option value="Odissi music">Odissi music</option>
              <option value="Patriotic">Patriotic</option>
              <option value="Poetry">Poetry</option>
              <option value="Pop">Pop</option>
              <option value="Qawwali">Qawwali</option>
              <option value="R&B">R&B</option>
              <option value="Rabindra Sangeet">Rabindra Sangeet</option>
              <option value="Raga">Raga</option>
              <option value="Raga rock">Raga rock</option>
              <option value="Rap">Rap</option>
              <option value="Rock">Rock</option>
              <option value="Romantic">Romantic</option>
              <option value="Sadra">Sadra</option>
              <option value="Semi-classical music">Semi-classical music</option>
              <option value="Sufi">Sufi</option>
              <option value="Sufi music">Sufi music</option>
              <option value="Sufi rock">Sufi rock</option>
              <option value="Sugama Sangeetha">Sugama Sangeetha</option>
              <option value="Tappa">Tappa</option>
              <option value="Tarana">Tarana</option>
              <option value="Thumri">Thumri</option>
              <option value="Trance">Trance</option>
              <option value="Trap">Trap</option>
              <option value="World music">World music</option>
            </select>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Sub-Genre
            </label>
            <SubGenreDropdown />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Mood
            </label>
            <select
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] p-[2%]"
              style={{ outlineStyle: "none" }}
            >
              <option value="Happy">Happy</option>
              <option value="Sad">Sad</option>
              <option value="Exuberant">Exuberant</option>
              <option value="Energetic">Energetic</option>
              <option value="Frantic">Frantic</option>
              <option value="Anxious">Anxious</option>
              <option value="Calm">Calm</option>
              <option value="Contentment">Contentment</option>
            </select>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Lyricist/Writer
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              value={writerValue}
              onChange={handleWriterChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Music Director/Director
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              value={directorValue}
              onChange={handleDirectorChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Singer/Artist
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              value={singerValue}
              onChange={handleSingerChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Licensor
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              value={licensorValue}
              onChange={handleLicensorChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Content Description
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              value={contentDescriptionValue}
              onChange={handleContentDescriptionChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Tags
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              value={tagsValue}
              onChange={handleTagsChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              ISRC
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              value={isrcValue}
              onChange={handleISRCChange}
              required
            />
          </div>
          <div>
            {isrcError && <span style={{ color: "red" }}>{isrcError}</span>}
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Copy rights
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              value={copyRightsValue}
              onChange={handleCopyRightsChange}
              required
            />
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Business Address
            </label>
            <div className="flex items-center bg-[#4c4c4c] rounded-[5px] w-[35rem] h-[2.8rem]">
              <input
                type="text"
                className="bg-transparent grow-[0.96] text-[#FFFFFF] font-[Poppins] pr-[2rem]"
                placeholder="Address"
                value={addressValue}
                onChange={handleAddressChange}
                required
                disabled={!isEditable}
              />
              <button className="mr-2" onClick={handleIconClick}>
                <img
                  src={"./assets/union-1.png"}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
              </button>
            </div>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Pan Card Number
            </label>
            <div className="flex items-center bg-[#4c4c4c] rounded-[5px] w-[35rem] h-[2.8rem]">
              <input
                type="text"
                className="bg-transparent grow-[0.96] text-[#FFFFFF] font-[Poppins] pr-[2rem]"
                placeholder="CCSDP274622"
                value={panCardValue}
                onChange={handlePanCardChange}
                required
                disabled={!isEditable}
              />
              <button className="mr-2" onClick={handlePancardIconClick}>
                <img
                  src={"./assets/union-1.png"}
                  alt=""
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
              </button>
            </div>
            {panCardError && (
              <span style={{ color: "red" }}>{panCardError}</span>
            )}
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Publishing Rights
            </label>
            <input
              type="text"
              className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins]"
              placeholder=""
              value={publishingRightsValue}
              onChange={handlePublishingRightsChange}
              required
            />
          </div>

          {contentType === "video" && (
            <>
              <div>
                <label
                  htmlFor=""
                  className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
                >
                  Keywords
                </label>
                <KeywordDropdown />
              </div>
              <div>
                <label
                  htmlFor=""
                  className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
                >
                  Rating
                </label>
                <select
                  className="rounded-[5px] bg-[#4c4c4c] w-[35rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] p-[2%]"
                  style={{ outlineStyle: "none" }}
                >
                  <option
                    className="hover:bg-[#FF6C2F] active:bg-[#c75525]"
                    value="U"
                  >
                    U
                  </option>
                  <option value="7+">7+</option>
                  <option value="13+">13+</option>
                  <option value="16+">16+</option>
                  <option value="18+">18+</option>
                </select>
              </div>
            </>
          )}
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Explicit Content
            </label>
            <div className="flex justify-start">
              <button
                className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF]  hover:bg-[#FF6C2F] active:bg-[#c75525]"
                onClick={handleYesClick}
              >
                Yes
              </button>
              <button
                className="w-[6rem] h-[3rem] bg-[#302F2F] rounded-md text-[#FFFFFF] mx-[20px] hover:bg-[#484848] active:bg-[#484848]"
                onClick={handleNoClick}
              >
                No
              </button>
            </div>
          </div>
          <div>
            <label
              htmlFor=""
              className="font-[Poppins] text-[#FFFFFF] mt-[4%] flex w-[80%] flex-row"
            >
              Release Date
            </label>
            <input
              type="date"
              className="rounded-[5px] bg-[#4c4c4c] w-[15rem] h-[2.8rem] text-[#FFFFFF] font-[Poppins] pl-[0.7rem] pr-5"
              placeholder=""
              required
            />
          </div>
          <div className="flex justify-end mb-[10%]">
            <button
              className="mt-[8%] text-[#FFFFFF] w-[6rem] h-[3rem] bg-[#FF6C2F] rounded-md hover:bg-[#d95a25] active:bg-[#c15122]"
              style={{
                fontFamily: "Poppins, sanSerif",
              }}
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div className="">
        {showAddressPopup && (
          <AddressPopup
            onClose={handleCloseAddressPopup}
            onSave={handleSaveAddress}
          />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default Details;
