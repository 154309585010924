import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingPage from "./components/LandingPage";
import SignInForm from "./components/SignIn";
import OtpVerification from "./components/OtpVerification";
import { ChangeNumber } from "./components/ChangeNumber";
import SignUp1 from "./components/SignUp1";
import SignUp2 from "./components/SignUp2";
import SignUp3 from "./components/SignUp3";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import { Routes, Route } from "react-router-dom";
import PublicRoutes from "./HOC/PublicRoutes";
import { publicRouteLink } from "./constant/Routes";
import TermsConditions from "./components/TermsConditions";
import PrivateRoutes from "./HOC/PrivateRoutes";
import PrivacyPolicy from "./components/PrivacyPolicy";
import MainContextProvider from "./context/main";
import toast, { Toaster } from "react-hot-toast";
import WithWrapper from "./components/withWrapper";
import Dashboard from "./components/Dashboard";
import Releases from "./components/releases";
import AccountDetails from "./components/AccountDetails";
import SocialMedia from "./components/SocialMedia";
import BankDetails from "./components/BankDetails";
import Uploads from "./components/Uploads";
import Details from "./components/Details";
import ContentDistribution from "./components/ContentDistribution";
import TermsUpload from "./components/TermsUpload";
import Iprs from "./components/Iprs";
import AttachFile from "./components/AttachFile";
import CoverImage from "./components/CoverImage";
import UploadSubmission from "./components/UploadSubmission";

function App() {
  return (
    <>
      <MainContextProvider>
        <Toaster />
        <Routes>
          <Route path={publicRouteLink.HOME} element={<PublicRoutes />}>
            <Route index element={<LandingPage />} />
            <Route path={publicRouteLink.ABOUT_US} element={<AboutUs />} />
            <Route path={publicRouteLink.CONTACT_US} element={<ContactUs />} />
            <Route
              path={publicRouteLink.SIGNIN}
              element={
                <WithWrapper>
                  <SignInForm />
                </WithWrapper>
              }
            />
            <Route
              path={publicRouteLink.OTP_VERIFICATION}
              element={
                <WithWrapper>
                  <OtpVerification />
                </WithWrapper>
              }
            />
            <Route
              path={publicRouteLink.CHANGE_PHONENUMBER}
              element={<ChangeNumber />}
            />
            <Route
              path={publicRouteLink.SIGNUP}
              element={
                <WithWrapper>
                  <SignUp1 />
                </WithWrapper>
              }
            />
            <Route
              path={publicRouteLink.COMPLETE_YOUR_ACCOUNT}
              element={<SignUp2 />}
            />
            <Route
              path={publicRouteLink.COMPLETE_YOUR_PROFILE}
              element={<SignUp3 />}
            />
            <Route
              path={publicRouteLink.TERMS_AND_CONDITIONS}
              element={<TermsConditions />}
            />
            <Route
              path={publicRouteLink.PRIVACY_POLICY}
              element={<PrivacyPolicy />}
            />
            {/* <Route path={publicRouteLink.RELEASES} element={<Releases />} /> */}
          </Route>

          <Route path={publicRouteLink.DASHBOARD} element={<PrivateRoutes />}>
            <Route index element={<Dashboard />} />
            {/* <Route path="/dashboard/releases" element={<Releases />} /> */}
          </Route>

          <Route path={publicRouteLink.RELEASES} element={<PrivateRoutes />}>
            <Route index element={<Releases />} />
          </Route>

          <Route path="/admin" element={<PrivateRoutes />}>
            <Route index element={<TermsConditions />} />
          </Route>

          <Route path="/" element={<PrivateRoutes />}>
            <Route
              exact
              path={publicRouteLink.ACCOUNT_DETAILS}
              index
              element={<AccountDetails />}
            />
            <Route
              exact
              path={publicRouteLink.SOCIAL_MEDIA}
              index
              element={<SocialMedia />}
            />
            <Route
              exact
              path={publicRouteLink.BANK_DETAILS}
              index
              element={<BankDetails />}
            />
          </Route>

          <Route path="/" element={<PrivateRoutes />}>
            <Route path={publicRouteLink.UPLOADS} index element={<Uploads />} />
            <Route
              path={publicRouteLink.DETAILS}
              index
              element={<Details />}
            />

            <Route
              path={publicRouteLink.CONTENT_DISTRIBUTION}
              index
              element={<ContentDistribution />}
            />
            <Route
              path={publicRouteLink.TERMS_UPLOADS}
              index
              element={<TermsUpload />}
            />
            <Route path={publicRouteLink.IPRS} index element={<Iprs />} />
            <Route
              path={publicRouteLink.ATTACH_FILE}
              index
              element={<AttachFile />}
            />
            <Route
              path={publicRouteLink.COVER_IMAGE}
              index
              element={<CoverImage />}
            />
            <Route
              path={publicRouteLink.UPLOAD_SUBMISSION}
              index
              element={<UploadSubmission />}
            />
          </Route>
        </Routes>
      </MainContextProvider>
    </>
  );
}

export default App;
