import React from "react";

const TermsConditions = () => {
    return (
        <>
            <div className="p-20 text-left">
                <h1 className="text-3xl"><b>TERMS & CONDITIONS</b></h1>
                <br />
                <p>The Terms and Conditions of use set forth herein apply to the website titled <a href="www.hungamaartistaloud.com" className="text-blue-600">www.hungamaartistaloud.com</a> which is owned, operated, licensed and controlled by Hungama Digital Entertainment Media Private Limited (hereinafter referred to as "Hungama", "we" and/or "us") and is covered and protected by all laws including but not limited to copyright and trademark laws of India.</p>
                <br />
                <h3><b>YOU AGREE</b></h3>
                <br />
                <p>Before you begin to explore and/or use the website, you ("you", "your", "yourself", and/or "user") will need to review the terms and conditions of use of the same. These terms and conditions will constitute a binding contract between You and Hungama ("Agreement"). These terms explain your obligation towards Hungama when you use the services of the website "www.hungamaartistaloud.com". By using this website, you agree that these terms of use are acceptable to you, and you agree to be bound by them. If you do not agree to these terms of use, please do not use this website and it is hereby stated by Hungama that using this website without accepting the terms and conditions is tantamount to unauthorized usage, punishable by law. Hungama reserves the right, at its discretion, to change, modify, add, alter, delete or remove portions of these terms. This Agreement shall continue to be in full force and effect for so long as you are using the services of the website. Your continued use of this website following the posting of any change or modification of these terms will mean you accept those changes or modifications. Hungama reserves the right to withdraw access to this website and to change, modify, revise the materials, products and pricing listed or offered through this website at any time without notice. It is your responsibility to check these terms and conditions periodically for changes.</p>
                <br />
                <h3><b>REGISTRATION DETAILS</b></h3>
                <br />
                <p>To avail the services being provided on the website, you will be required to submit the information/data as required at the time of registering with the website. You agree to provide Hungama with accurate, complete and updated registration information. If you do not provide Hungama with accurate, complete and updated registration information, you are not authorized to use the services of the website and Hungama has the right to indefinitely suspend or terminate your account and refuse to provide you with access to. You further agree that, in providing such registration information, you will not knowingly or otherwise omit or misrepresent any material facts or information and that you will promptly enter correct and/or updated registration data/information, or otherwise advise us promptly in writing of any such changes or updates. You further consent and authorize Hungama to verify your registration data as may be required for your use of and access to the services of the website, as applicable.</p>
                <br />
                <h3><b>MODIFICATION OF THE TERMS.</b></h3>
                <br />
                <p>Hungama reserves the right, at our discretion, to change, modify, add, or remove portions of these Terms, at any time for any reason, and we may notify you of such changes through any of a variety of means, and other reasonable means to be determined in our discretion. All changes shall be effective immediately. Kindly check these Terms periodically for changes. Your continued use of any service following our posting of the change of terms will constitute your voluntary acceptance of the change.</p>
                <br />
                <h3><b>DEFINITIONS:</b></h3>
                <br />
                <h2>1.	RECORDINGS</h2>
                <br />
                <p>It shall be defined as the sound recordings and audiovisual recordings that you submit to Hungama at any time. Hungama, in its sole discretion, reserves the right to reject any materials (including, without limitation, Recordings, images and/or artwork) that you submit. You agree to submit all Recordings, images, and artwork at your sole expense, in the format(s) required by Hungama or the platforms. Technical descriptions of such format(s) will be provided to you upon request, provided that Hungama reserves the right to convert audio files of the Recordings as necessary to meet the format and/or file size requirements of all platforms. In addition, Hungama will have the right to correct metadata errors to meet the platform requirements. Hungama undertakes to provide your Recordings to platforms and make your Recordings available to customers. Reasonable efforts will be made to provide you with approval rights over such artwork, but in the event that you object to any such artwork your only remedy shall be to (a) provide appropriate replacement artwork or (b) request prospective discontinuation of delivery of your materials in such manner.</p>
                <br />
                <h2>2.	USER GENERATED CONTENT:</h2>
                <br />
                <p>As used herein, “User Generated Content” shall mean derivative works embodying your Recordings, artwork (including single and album artwork), and other material provided by you in connection with the Recordings which are created by third parties (User Generated Content/User Content). You agree that Hungama shall have the right to use and exploit the User Generated Content embodying your Recordings, artwork, and other material provided by you in connection with the Recordings, as permitted and enabled by the features of the applicable platforms you opt to distribute your Recordings to. User Generated Content includes, but is not limited to: (i) videos and visual images synchronized in time relation with musical recordings; (ii) audio or audiovisual “remixes” which combine two or more recordings which may be edited together, mixed, remixed, edited or “mashed up”; (iii) visual or audiovisual material which manipulates still or visual images through editing and filters and (iv) other adaptations, alterations and manipulations of the Recordings, artwork and other material provided by you, by third parties. </p>
                <br />
                <h2><b>TERM</b></h2>
                <br />
                <p>The Terms of Service shall apply at all times while you utilize the site or the Services.</p>
                <br />
                <h2><b>GRANT OF RIGHTS:</b></h2>
                <br />
                <p>You grant Hungama a royalty-free, irrevocable, and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Generated Content throughout the world in any media. You grant Hungama and/or its sub-licensees the right to use the name that you submit in connection with such content if they choose. </p>
                <br />
                <h2><b>PARTY/PARTIES</b></h2>
                <br />
                <p>You and Hungama shall individually be referred to as "the Party" and collectively as "the Parties" unless otherwise stated.</p>
                <br />
                <h2><b>SERVICES INCLUDE:</b></h2>
                <br />
                <p>1) Audio and Video Upload<br />
                    2) Distribution Deal<br />
                    3) Promotional Plan<br />
                </p><br />
                <h2><b>USER ACCOUNT & SECURITY</b></h2>
                <br />
                <p>USER ACCOUNT & SECURITY: All registered users shall have a user name and password. It shall be the sole responsibility of the users to maintain the confidentiality and security of their account. You should not reveal your account information to anyone else or use anyone else's account. Your 'user account' and password are personal and should be kept confidential. You agree that you will not allow another person to use your 'user account' to access and use the service under any circumstances. You are solely and entirely responsible for maintaining the confidentiality of your 'user account' and for any charges, damages, liabilities or losses incurred or suffered as a result of your failure to do so. Hungama is not liable for any harm caused by or related to the theft of your account information, your disclosure of your 'user account', or your authorization to allow another person to access and use the services using your 'user account'. Furthermore, you are solely and entirely responsible for any and all activities that occur under your account and you agree to immediately notify us of any unauthorised use of your account or any other breach of security.</p>
                <br />
                <p>Although Hungama will not be liable for any losses you may incur due to any unauthorized use of your 'user account', you may be liable for the losses of Hungama and/or other users due to such unauthorized use. In consideration of your use of the website and services provided therein, you agree, where information is requested, to provide true, accurate, current, and complete information about yourself, and to maintain and update it promptly. You agree that if any information you provide is false, inaccurate, not current, or incomplete, we may terminate your use of the website and/or the services provided there under.</p>
                <br />
                <h2><b>INTELLECTUAL PROPERTY RIGHTS:</b></h2>
                <br />
                <p>Intellectual Property rights related to the User Generated Content: You agree that you have the full right, title and interest in and to all Intellectual Property Rights in the User Generated Content and you are legally authorized to provide us the same. You represent and warrant that Hungama shall be entitled to exploit the User Generated Content without any obstruction or interference from any third party. You agree to provide the royalty free User Generated Content to Hungama. </p>
                <br />
                <p>Hungama Site :The Hungama Sites including the content, visual interfaces, interactive features, audio, video, audio-visual material, information, graphics, design, compilation, computer code, products, software, services, proprietary information, service marks, trademarks, trade names, distinctive information (such as logos), the selection, sequence, “look and feel” and arrangement of items, and all other elements of the Hungama Sites that are provided by Hungama (“Hungama Materials”), are owned and/or licensed by Hungama, and are legally protected, without limitation, under the laws and regulations of India, as applicable. Hungama Materials do not include third party content. Except as expressly authorized by Hungama, you agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of, the Hungama Sites and/or the Hungama Materials. Hungama reserves all rights not expressly granted in these Terms. You shall not acquire any right, title, or interest to the Hungama Materials, except for the limited rights expressly set forth in these Terms. All those intellectual property rights arising as a result of domain names, Internet or any other form of an electronic/technological law shall also vest in the domain of Hungama. The Parties hereto agree and confirm that no part of any intellectual property rights mentioned hereinabove is transferred in the name of user and any intellectual property rights arising as a result of these sites shall also be in the absolute ownership, possession and control of Hungama.</p>
                <br />
                <p>You shall promptly notify Hungama in writing upon your discovery of any unauthorized use or infringement of the services (or its contents) or Hungama's patent, copyright, trade secret, trademarks or other intellectual property rights. The services provided contain proprietary and confidential information that is protected by copyright laws and international treaty provisions.</p>
                <br />
                <h2><b>REPRESENTATIONS AND WARRANTIES :</b></h2>
                <br />
                <p>In connection with User Content, you affirm, represent, and/or warrant that: (a) you own, or have the necessary licenses, rights, consents, and permissions to use and authorize Hungama to use, the copyright, or other proprietary rights in and to any and all User Content to enable inclusion and use of User Content in the manner contemplated by Hungama and these Terms, and to grant the rights and license as set forth in this Section, and (b) your User Content, Hungama’s use of such User Content pursuant to these Terms, and Hungama’s exercise of the license rights set forth in these Terms, do not and will not: (i) infringe, violate, or misappropriate any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or (iii) violate any applicable law or regulation. You represent and warrant that you own or otherwise control all of the rights to the User Generated content; that the content is accurate; that use of the content you supply does not violate this policy; </p>
                <br />
                <p>You are solely responsible for the content including without limitation, the text, software, scripts, graphics, photos, sounds, music, videos, interactive features. Hungama makes no warranties, express or implied, as to the content submitted by you or to the accuracy and reliability of such content or any material or information that you transmit to other users.</p>
                <br />
                <p>You agree that to the extent that you select certain Recordings from your catalogue provided to Hungama, to distribute free to fans or directly through your own website, you agree that you will be solely responsible for any of the above referenced third party payment obligations resulting from such deliveries.</p>
                <br />
                <p>Hungama may delete any such User Generated content, for any or no reason, including postings that in our sole judgment violate these terms and conditions of use or which may be offensive, illegal or violate the rights of any person or entity, or harm or threaten the safety of any person or entity. Hungama assumes no responsibility for inappropriate content by you/users or conduct of the users on this website. </p>
                <br />
                <p>Hungama shall have the right, but not the obligation, to review and/or monitor any activity and content uploaded by you. Hungama may investigate any complaints or any reported violation of its policies and may take any action that it deems appropriate. Such action may include, but is not limited to, issuing warnings, suspension or termination of service, denying access and/or removal of any materials you may have uploaded. You agree that Hungama reserves the right and may terminate your account in its sole discretion for any reason and at any time, including if, in Hungama’s good faith discretion, you are believed to have engaged in improper Conduct.</p>
                <br />
                <p>KINDLY NOTE THAT HUNGAMA PROHIBITS PORNOGRAPHY ON ITS WEBSITE. Please choose carefully the information you post on the website and that you provide to other users. Your profile must not include any photographs containing nudity, or obscene, lewd, excessively violent, harassing, sexually explicit or otherwise objectionable subject matter. STRINGENT PENAL AND CRIMINAL ACTIONS WILL BE INITIATED AGAINST THOSE FOUND INDULGING IN ABOVE REFERRED ACTIVITIES. Any user or member who publishes or transmits or causes to be published in the electronic form, any material which is lascivious or appeals to the prurient interest or if its effect is such as to tend to deprave and corrupt people who are likely, having regard to all relevant circumstances, to read, see or hear the matter contained or embodied in it shall be strictly treated under the provision of Section 67 of the Information Technology Act 2000 and as per the provisions of Indian Penal Code and other appropriate statutes applicable in that scenario. Despite this prohibition, information provided by other users (for instance, in their profile) may contain inaccurate, inappropriate, offensive or sexually explicit material, products or services, and Hungama assumes no responsibility or liability for this material. If you become aware of misuse of the services provided by Hungama by any person, please contact Privacy Coordinator.</p>
                <br />
                <h2><b>TERMINATION:</b></h2>
                <br />
                <p>If you violate, breach or fail to comply with any clause of this Agreement, we may terminate this Agreement or restrict or suspend or terminate your use of the services of immediately and without notice. You may terminate the services by ceasing to use the website. </p>
                <br />
                <p>We reserve the right to terminate your use of the services, without cause, upon reasonable notice. </p>
                <br />
                <p>The failure of Hungama to take any action in respect of a breach of these terms and conditions shall not constitute a waiver of their enforceability. Hungama reserves its rights in respect of these terms and conditions at all times.</p>
                <br />
                <h2><b>OTHER AGREEMENTS.</b></h2>
                <br />
                <p>(a) You acknowledge that in providing the Services and payments hereunder, Hungama will be required to enter into certain agreements with various platforms. The selection of these platforms shall be within the sole discretion of Hungama. You agree that the Terms of Service shall be subject to any applicable terms and conditions of such other agreements that Hungama enters into with respect to such platforms. You expressly acknowledge that certain platforms may, with respect to audiovisual recordings, require that your audiovisual recordings be made available on their websites at certain times and in certain formats relative to the availability of such materials elsewhere in the marketplace. To the extent that such requirements are not met, the individual platforms may have the right to discontinue the availability of said audiovisual recordings in their store(s). Hungama may, upon your written request, provide you with the current specifics of such requirements.</p>
                <br />
                <p>(b) From time to time during the Term, individual platforms may make changes to their platform, including, but not limited to, adding or removing specific services or products, developing and beta testing new products, running promotions, and creating alternate royalty calculations and/or pricing structures (individually and collectively, “New Store Development(s)”). By entering into this agreement, you understand that as part of the Services, in connection with any platforms which you select and approve distribution to, you may be automatically opted into such New Store Developments made by such platform during the Term.</p>
                <br />
                <h2><b>LINKS TO OTHER SITES:</b></h2>
                <br />
                <p>The website may contain links to other independent third-party web sites ("Linked Sites"). These Linked Sites are provided solely as a convenience to the users. Such Linked Sites are not under Hungama's control, and Hungama is not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.</p>
                <br />
                <h2><b>TECHNOLOGY LIMITATIONS AND MODIFICATIONS TO SERVICE:</b></h2>
                <br />
                <p>It is the duty and responsibility of Hungama to keep your account and the service(s) operational. However, in case of any technical difficulties or for maintenance, if it results in temporary interruption, Hungama will make reasonable efforts to rectify the snag as early as possible.</p>
                <br />
                <p>If, for any technical reason and under unavoidable circumstances Hungama needs to discontinue, temporarily or permanently, functions and features of the application and service(s) with or without notice, or if any tracks in any particular catalogue may no longer be available due to technical or contractual rights, Hungama reserves right to change and replace the tracks or albums or any other content available at any time.</p>
                <br />
                <p>Under the said circumstances, Hungama shall not be liable for any of the direct or indirect consequences of any modification, malfunction, suspension, discontinuance of or interruption to or of any of the services as the same may be treated as Force Majeure.</p>
                <br />
                <p>You also shall not use, nor allow others to use, your account, the services, directly or indirectly, to: (a) attempt to or actually disrupt, impair or interfere with, alter or modify the services or any information, data or materials posted and/or displayed by us or anyone else; (b) act in a way that affects or reflects negatively on us, the services, or anyone else; (c) collect or attempt to collect any information from others including, without limitation, personally identifiable information, without such party's prior consent. You agree to comply with all local, state, federal laws, statutes, rules and regulations, as well as any international treaties, which are applicable to your use of the website.</p>
                <br />
                <p>You are prohibited from violating or attempting to violate the security of the website, including, without limitation: (a) accessing data not intended for you or logging onto a processor, communications or access device or account which you are not authorized to access; (b) attempting to probe, scan or test the vulnerability of the website or to breach security or authentication measures, regardless of your motives or intent; (c) attempting to interfere with or disrupt the services to any user, processor, host or network, including, without limitation, by submitting a virus, worm or Trojan horse; or (d) sending unsolicited e-mail or other information, including promotions or advertising. Violations of system or network security or this Agreement may result in civil or criminal liability. We have the right to investigate occurrences, which may involve such violations and we may involve, provide information to, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use or launch any automated system, including without limitation, "robots," "spiders," "offline readers," etc., that accesses the website in a manner that sends more request messages to the Hungama servers in a given period of time than a human can reasonably produce in the same period by using a convention on-line web browser. Notwithstanding the foregoing, Hungama grants the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly available searchable indices of the materials, but not caches or archives of such materials. Hungama reserves the right to revoke these exceptions either generally or in specific cases. You agree not to collect or harvest any personally identifiable information, including account names, from the website, nor to use the communication systems provided by the website for any commercial solicitation purposes.</p>
                <br />
                <p>Any illegal and/or unauthorized use of the services and/or the website, including unauthorized framing of or linking to the website will be investigated and appropriate legal action will be taken, including without limitation, civil, criminal and injunctive redress.</p>
                <br />
                <h2><b>DISCLAIMER:</b></h2>
                <br />
                <p>AS PROVIDED ABOVE, ALL INFORMATION, TECHNOLOGY AND SERVICE PROVIDED SHALL, AT ALL TIMES, BE ON A "AS IS" AND "AS AVAILABLE" BASIS AND HUNGAMA HEREBY STATES THAT ANY REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE APPLICATION OR ITS OPERATION IS NOT UNDERTAKEN BY HUNGAMA. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, HUNGAMA AND ITS DISTRIBUTION AND SYNDICATION PARTNERS, DISCLAIM ALL WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT. HUNGAMA MAKES NO WARRANTY THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, OR THAT THE APPLICATION SHALL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE; NOR DOES HUNGAMA MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE APPLICATION OR AS TO THE ACCURACY OR RELIABILITY OF ANY CONTENT OBTAINED THROUGH THE APPLICATION. HUNGAMA MAKES NO WARRANTY REGARDING ANY GOODS OR SERVICES PURCHASED OR OBTAINED THROUGH THE APPLICATION OR ANY TRANSACTIONS ENTERED INTO THROUGH THE APPLICATION.</p>
                <br />
                <h2><b>LIMITATION OF LIABILITY:</b></h2>
                <br />
                <p>HUNGAMA HAS MADE THIS SERVICE AVAILABLE TO USERS AS A MATTER OF CONVENIENCE. HUNGAMA EXPRESSLY DISCLAIMS ANY CLAIM OR LIABILITY ARISING OUT OF UPLOADING OF ANY OBSCENE, VULGAR OR PORNOGRAPHIC CONTENT OR ALTERING OR DISTORTING THE CONTENT AVAILABLE UNDER THE SERVICE IN AN OBSCENE, VULGAR OR PORNOGRAPHIC MANNER. YOU AGREE AND ACKNOWLEDGE THAT YOU SHALL BE SOLELY RESPONSIBLE FOR YOUR CONDUCT AND THE HUNGAMA RESERVES THE RIGHT TO TERMINATE YOUR RIGHTS TO USE THE SERVICE IMMEDIATELY, NOTWITHSTANDING PENAL PROVISIONS UNDER THE APPLICABLE LAWS.</p>
                <br />
                <p>IN NO EVENT SHALL HUNGAMA OR ANY OF THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR AFFILIATES BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR OTHER PECUNIARY LOSS) ARISING OUT OF THIS AGREEMENT OR THE USE OF OR INABILITY TO USE ANY SERVICE FOR INTERRUPTED COMMUNICATIONS, DELAY, LOST DATA OR LOST PROFITS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, EVEN IF HUNGAMA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU, BUT THEY SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW. </p>
                <br />
                <p>THIS CLAUSE SHALL SURVIVE THE TERMINATION OR EXPIRY OF THIS AGREEMENT.</p>
                <br />
                <h2><p>INDEMNIFICATION:</p></h2>
                <br />
                <p>You hereby agree to indemnify and hold Hungama, its agents, contractors, members, subsidiaries, affiliates, service providers, syndicators, distributors, licensors, officers, directors and employees, harmless from and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, suffered by Hungama as a result of a breach of the terms and conditions of this Agreement by you and from any claim brought by third parties alleging that use of the services and/or website by you or under your account has infringed any intellectual property or other right of any kind, or any applicable laws or regulation including but not limited to payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or publicity, loss of service by other users and infringement of intellectual property or other rights. You shall provide Hungama with notice of such claims, full authority to defend, compromise or settle such claims and reasonable assistance necessary to defend such claims, at your sole expense.</p>
                <br />
                <h2><b>GOVERNING LAW:</b></h2>
                <br />
                <p>Any dispute arising out of the terms and conditions set herein, the website, or the services available on the website shall be governed by the laws of India and the High Court of Mumbai, India shall have the non-exclusive jurisdiction with respect thereto. Use of the website is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including without limitation this clause. </p>
                <br />
                <h2><b>GENERAL PROVISIONS:</b></h2>
                <br />
                <p>No failure or delay in enforcing any provision, exercising any option or requiring performance, shall be construed to be a waiver of that or any other right in connection with this Agreement.</p>
                <br />
                <p>If any provision of this Agreement is invalid or unenforceable under applicable law, it is, to that extent, deemed omitted and the remaining provisions will continue in full force and effect. This Agreement will bind and inure to the benefit of each party's permitted successors and assigns. This Agreement is personal to you and may not be transferred, assigned or delegated to anyone. Any attempt by you to assign, transfer or delegate this Agreement shall be null and void. This Agreement (including all documents expressly incorporated herein by reference, including but not limited to the Hungama's Privacy Policy and the end user license agreement) constitutes the complete and exclusive agreement between Hungama and you with respect to the subject matter hereof and supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and Hungama with respect to the services and the use of the website.</p>
                <br />
                <p>You agree and undertake that you are accessing the services available on this website and transacting at your sole risk and are using your best and prudent judgment before entering into any transaction through this website.</p>
                <br />
                <p>Hungama accepts no responsibility/liability for any errors or omissions, whether on behalf of itself or third parties. Further Hungama shall not be responsible for any non-performance or breach of any contract entered into between users.</p>
                <br />
                <p>This Agreement, together with our Privacy Policy, the end user license agreement and any other rules, regulations, procedures and policies herein, constitutes the entire agreement between you and Hungama with respect to the services on the website and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and Hungama with respect to the services.</p>
                <br />
                <p>The Hungama site is registered in India. If you are a user / member accessing the website from any other region with laws or regulations governing personal data collection, user, protection and disclosure, that differ from the applicable Indian laws, please note that through your use of the website, Privacy Policy, end use license agreement and this Terms and Conditions of Use, you are transferring your personal information to India and you are giving a complete consent for the mentioned transfer. Hungama will at any point of time, be complying with the legal provisions on the subject matter, which are at the time applicable in India.</p>
                <br />
                <p>The terms herein are subject to prevailing laws and legal process, and nothing contained in this Agreement is in derogation of our right to comply with statutory requests or requirements relating to your use of the services or information provided to or gathered by Hungama with respect to such use.</p>
                <br />
                <p>If use of the services and the website is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, it shall be governed by laws of India.</p>
                <br />
                <p>This Agreement or any other communication in electronic form or printed shall be admissible in judicial or administrative proceedings as if produced in printed form.</p>
                <br />
                <p>You hereby confirm that you shall abide to these Terms & Conditions and shall also abide to all the applicable laws of the land.</p>
            </div>
        </>
    )
};

export default TermsConditions;
